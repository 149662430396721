import { Logger } from 'core/logger';
import { HTTP_METHODS, mmNetworkService } from '../../../../core/network/mmServicesApiProvider';
import { EditorServiceDataProvider } from './editorServiceConfigsProvider';

export class EditorNetworkService {
  currentRevision = 0;

  lastSavedDraftVersion = null;

  static getDrafts(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getDraftsEndpoint(property), configs);
  }

  static getSubmittedDrafts(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getSubmittedDraftsEndpoint(property), configs);
  }

  static getPublishedArticles(property) {
    const configs = {
      method: HTTP_METHODS.GET,
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getPublishedArticlesEndpoint(property), configs);
  }

  static deleteDraft({ property, id }) {
    const configs = {
      method: 'DELETE',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getDeleteDraftEndpoint({ property, id }), configs);
  }

  getDraft({ property, id }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getEditorServiceDraftEndpoint({ property, id }), configs)
      .then(data => {
        const { draft, revision, isWorkingDraft } = data;
        this.currentRevision = revision;
        this.lastSavedDraftVersion = draft;
        return { draft, isWorkingDraft };
      })
      .catch(e => {
        throw e;
      });
  }

  createDraft(property) {
    const configs = {
      method: 'POST',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getEditorServiceCreateDraftsEndpoint(property), configs)
      .then(data => {
        this.currentRevision = data.revision;
        return data.id;
      });
  }

  updateDraft({ property, id, draft, updateCounter }) {
    const body = {
      revision: this.currentRevision,
      draft,
    };
    if (id) {
      const configs = {
        method: 'PUT',
        body: JSON.stringify(body),
      };
      const updateDraftEndpoint = `${EditorServiceDataProvider.getEditorServiceDraftEndpoint({
        property,
        id,
      })}?updateCounter=${updateCounter}`;
      return mmNetworkService.fetch(updateDraftEndpoint, configs)
        .then(data => {
          this.currentRevision = data.revision;
          this.lastSavedDraftVersion = data.draft || {};
          return data;
        }).catch(e => {
          Logger.error(e);
          return Promise.reject(e);
        });
    }
    return Promise.reject(Error('no draftId to update'));
  }

  static getDraftsByAuthors(property, userIds) {
    const body = { userIDs: userIds };
    const configs = {
      method: 'POST',
      body: JSON.stringify(body),
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getDraftsByAuthorsEndpoint(property), configs);
  }

  static getWriters(property) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getWritersEndpoint(property), configs);
  }

  static updateComments({ property, draftId, entityKey: entityId, comments }) {
    const configs = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify({ comments }),
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getEditorServiceCommentsEndpoint({
      property,
      draftId,
      entityId,
    }), configs);
  }

  static deleteComments({ property, draftId, entityKey: entityId }) {
    const configs = {
      method: HTTP_METHODS.DELETE,
    };

    return mmNetworkService.fetch(EditorServiceDataProvider.getDeleteCommentsEndpoint({
      property,
      draftId,
      entityId,
    }), configs);
  }

  submitDraft({ property, id }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify({ revision: this.currentRevision }),
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getEditorServiceSubmitDraftEndpoint({
      property,
      id,
    }), configs);
  }

  updatePublishedDraft({ property, id }) {
    const configs = {
      method: 'POST',
      body: JSON.stringify({ revision: this.currentRevision }),
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getEditorServiceUpdateDraftEndpoint({
      property,
      id,
    }), configs);
  }

  static getDraftPreview({ property, id, isPremiumArticlePreview }) {
    const configs = {
      method: 'POST',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getDraftPreviewEndpoint({ property, id, isPremiumArticlePreview }), configs);
  }

  static getVmsPlayers({ vmsProperty }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getVmsPlayersEndpoint({ vmsProperty }), configs);
  }

  static searchVmsVideos({ vmsProperty, searchQuery, searchLimit, searchOffset }) {
    const configs = {
      method: 'GET',
    };
    return mmNetworkService.fetch(EditorServiceDataProvider.getSearchVmsVideosEndpoint({
      vmsProperty,
      searchQuery,
      searchLimit,
      searchOffset,
    }), configs);
  }
}

let instance;

export const getEditorServices = () => {
  if (!instance) {
    instance = new EditorNetworkService();
  }
  return instance;
};
