import { ConfigsProvider } from '../../../../core/configs/ConfigsProvider';

const ECHO_SERVICES_BASE_URL = ConfigsProvider.NEW_ECHO_API_BASE_URL;
const PROPERTIES = '/properties';
const POSTS = '/posts';
const FEEDS = '/feeds';
const ADS_CATEGORIES = '/adscategories';
const AUTHORS = '/authors';
const METADATA = '/metadata';
const PATH = '/path';
const COMMENTS = '/comments';
const HIDDEN = '/hidden';
const SCHEDULED = '/scheduled';
const SCHEDULE = '/schedule';
const UNPIN = '/unpin';
const FEATURE = '/feature';
const MEDIA_SERVICES = '/media';
const IMAGE_SIGNED_URL_ENDPOINT = '/signed-upload-url';
const LEGACY = '/legacy';
const TOPIC = '/topic';
const LIMIT = 'limit';
const PREVIEW = '/preview';
const MAIN_CATEGORIES = '/maincategories';
const MAIN_CATEGORY = '/maincategory';
const LABELS = '/labels';
const CUSTOM_CANONICAL_URL = '/custom-canonical-url';
const RECOMMENDATIONS = '/recommendations';
const PUBLISH_DATE = '/migrate';
const PROPERTIES_NAMESPACE = '/properties';
const MANAGEMENT_NAMESPACE = '/management';
const PROPERTY_SETTINGS_NAMESPACE = '/settings';
const TAXONOMY = '/taxonomy';
const IN_THIS_STORY = '/inthisstory';

function readConfigObject() {
  const configObject = document.getElementById('config');
  return configObject ? JSON.parse(document.getElementById('config').innerHTML) : null;
}

export class EchoServiceConfigsProvider {
  static getAvailablePropertiesEndpoint() {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}`;
  }

  static getPostListEndpoint({ property, queryString }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}?${queryString}`;
  }

  static getPaginationPostListEndpoint({ path }) {
    return `${ECHO_SERVICES_BASE_URL}${path}`;
  }

  // This endpoint is used for getting post data
  static getPostDataEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}`;
  }

  static getScheduledPostsListEndpoint({ property, queryString }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${SCHEDULED}?${queryString}`;
  }

  static getUpdateCommentsEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${COMMENTS}`;
  }

  static getUpdateVisibilityEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${HIDDEN}`;
  }

  static getAvailableFeedsEndpoint(property) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${FEEDS}`;
  }

  static getAvailableAdsCategoriesEndpoint(property) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${ADS_CATEGORIES}`;
  }

  static getAvailableAuthorsEndpoint(property) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${AUTHORS}`;
  }

  static getUpdatePostFeedsEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${FEEDS}`;
  }

  static getFeaturePostEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${FEATURE}`;
  }

  static getUpdatePostMetadataEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${METADATA}`;
  }

  static getUpdatePostExcludeFromRecommendationsEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${RECOMMENDATIONS}`;
  }

  static getUpdatePostCustomCanonicalURLEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${CUSTOM_CANONICAL_URL}`;
  }

  static getUpdatePostPublishDateEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${PUBLISH_DATE}`;
  }

  static getUpdatePostPathEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${PATH}`;
  }

  static getUpdatePostScheduledFeedsEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${SCHEDULE}`;
  }

  static getUnpinFeedEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${UNPIN}`;
  }

  static getUpdatePostAuthorsEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${AUTHORS}`;
  }

  static getSignedUploadImageEndpoint(filename, uploadParamsString, property) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${MEDIA_SERVICES}${IMAGE_SIGNED_URL_ENDPOINT}/${filename}?${uploadParamsString}`;
  }

  static getPushNotificationFeedsEndpoint(property, postId) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${LEGACY}${POSTS}/${postId}${FEATURE}`;
  }

  static getFeaturedRecommendationArticlesEndpoint({ property, additionalPostsTopic, limit }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${TOPIC}/${additionalPostsTopic}${POSTS}?${LIMIT}=${limit}`;
  }

  static getAddPostMainCategoryEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${MAIN_CATEGORY}`;
  }

  static getMainArticleEndPoint({ property }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${MAIN_CATEGORIES}`;
  }

  static getArticlePreviewEndpoint({ property, id, isPremiumArticlePreview }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${id}${PREVIEW}${isPremiumArticlePreview ? '?isPremium=true' : ''}`;
  }

  static getUpdateLabelsEndpoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${LABELS}`;
  }

  static getPropertySettingsEndPoint({ property }) {
    return `${readConfigObject().CRAFT_API_BASE_URL}${PROPERTIES_NAMESPACE}/${property}${MANAGEMENT_NAMESPACE}${PROPERTY_SETTINGS_NAMESPACE}`;
  }

  static getTaxonomyEndPoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${TAXONOMY}`;
  }

  static getInThisStoryEndPoint({ property, postId }) {
    return `${ECHO_SERVICES_BASE_URL}${PROPERTIES}/${property}${POSTS}/${postId}${IN_THIS_STORY}`;
  }
}
