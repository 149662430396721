export const PREMIUM_EDITORS = [
  'melissa-g',
  'jamie.lisanti',
  'michalsp',
  'sebaseba',
  'omer-ami',
  'samantha-m',
  'ido-cohen',
  'rom-zu',
  'alec-f',
  'noarrr',
  'alex-moeller-si',
  'alex-moeller',
  'yarinb',
  'yarinbiton',
  'marissa-mm',
  'yuvalf',
  'kristen-nelson',
  'marissa-mcd',
];
