import React from 'react';
import { Text } from '@ftbpro/mm-admin-ui-components';
import {
  EDIT_URL_MIN_LENGTH,
  DEFAULT_ADS_CATEGORY,
  SPONSORED_ERROR_MESSAGES,
  SPONSORED_ARTICLE_FIELDS,
  ALLOWED_FILE_EXTENSION,
} from './echoMetadata.constants';
import { SITE_VARIABLES } from '../../../../../../core/styles/variables';

function splitOnLastOccurrence(str, substr) {
  const arr = str.split(substr);
  const after = arr.pop();
  const before = arr.join(substr);
  return [before, after];
}

const splitEditUrlOnLastSlash = url => splitOnLastOccurrence(url, '/');

export const isEditUrlValid = (url = '') => {
  const containDoubleSlash = url.includes('//');
  const [, suffix] = splitEditUrlOnLastSlash(url);
  const hasValidSuffixLength = suffix.length >= EDIT_URL_MIN_LENGTH;

  return !containDoubleSlash && hasValidSuffixLength;
};

export function sanitizeEditUrl(url) {
  return url.replace(/[^a-zA-Z0-9-_/]/gi, '');
}

export const deleteTagFromTagList = (tagList, tagToRemove) => tagList.filter(e => e !== tagToRemove);

export const doesTagExists = (tag, tags) => {
  return tags.indexOf(tag) > -1;
};

export const validateSponsoredContentData = sponsoredContent => {
  return sponsoredContent
    && sponsoredContent[SPONSORED_ARTICLE_FIELDS.TEXT.FIELD_NAME]
    && sponsoredContent[SPONSORED_ARTICLE_FIELDS.BRAND_NAME.FIELD_NAME]
    && sponsoredContent[SPONSORED_ARTICLE_FIELDS.URL.FIELD_NAME]
    && sponsoredContent[SPONSORED_ARTICLE_FIELDS.IMAGE.FIELD_NAME];
};

export const isBettingContentValid = bettingContent => {
  return bettingContent && Object.values(bettingContent).some(value => !!value);
};

export const generateMetadataObject = ({
  noIndex,
  isPremiumArticle,
  excludeFromRSS,
  canonicalUrl,
  commercialTags,
  adsCategory,
  sponsoredContent,
  isSponsoredContentEnabled,
  hideOwner,
  additionalPostsTopic,
  owner,
  bettingContent,
  isBettingContentEnabled,
}) => {
  const metadata = {};
  if (commercialTags.length) {
    metadata.commercialTags = commercialTags;
  }
  if (noIndex) {
    metadata.noIndex = noIndex;
  }
  if (isPremiumArticle) {
    metadata.isPremium = isPremiumArticle;
  }
  if (excludeFromRSS) {
    metadata.excludeFromRSS = excludeFromRSS;
  }
  if (canonicalUrl) {
    metadata.canonicalUrl = canonicalUrl;
  }
  if (hideOwner) {
    metadata.hideOwner = hideOwner;
  }
  if (isSponsoredContentEnabled && validateSponsoredContentData(sponsoredContent)) {
    metadata.sponsor = sponsoredContent;
  }
  if (additionalPostsTopic) {
    metadata.additionalPostsTopic = additionalPostsTopic;
  }
  if (owner) {
    metadata.owner = owner;
  }
  if (isBettingContentEnabled && isBettingContentValid(bettingContent)) {
    metadata.betting = bettingContent;
  }
  metadata.adsCategory = adsCategory || DEFAULT_ADS_CATEGORY;
  return metadata;
};

export const validateSponsoredAsset = image => {
  if (!image?.imageData) {
    return {
      isValid: false,
      errorMessage: SPONSORED_ERROR_MESSAGES.IMAGE_IS_NOT_VALID,
    };
  }
  const { extension } = image.imageData;
  if (extension !== ALLOWED_FILE_EXTENSION) {
    return {
      isValid: false,
      errorMessage: SPONSORED_ERROR_MESSAGES.FILE_EXTENSION,
    };
  }
  return {
    isValid: true,
    errorMessage: '',
  };
};

const createDropdownItem = article => {
  return ({
      content: <Text style={{ whiteSpace: 'break-spaces', color: SITE_VARIABLES.COLORS.BLACK }} type={Text.TEXT_TYPES.PARAGRAPH_L}>{article.title}</Text>,
      article,
      id: article.id,
      value: article.id,
    }
  );
};

export const customDropdownList = recommendationsList => {
  if (recommendationsList && recommendationsList.length !== 0) {
    return (
      recommendationsList.map(article => createDropdownItem(article))
    );
  }
  return recommendationsList;
};

export const getAvailableRecommendationArticlesForDropdown = (selectedArticles, dropdownRecommendationList) => {
  return dropdownRecommendationList.filter(article => {
    return !selectedArticles.some(selectedArticle => selectedArticle.id === article.id);
  });
};

export const deleteArticleFromRecommendationList = (articleList, articleToRemove) => {
  return articleList.filter(article => article.id !== articleToRemove);
};

export const insertArticleToRecommendationList = (articleList, articleToInsert) => {
  return [articleToInsert, ...articleList];
};

export const dropdownItemToString = item => {
  return item ? `${item.article.title} ${item.id}` : '';
};

export const mapMainCategoryDisplayNameToMainCategorySlug = (newlySelectedCategoryDisplayName, availableMainCategories) => {
  const newlySelectedMainCategory = Object.keys(availableMainCategories).find(category => {
    return availableMainCategories[category].displayName === newlySelectedCategoryDisplayName;
  });

  return availableMainCategories[newlySelectedMainCategory]?.slug ?? '';
};
