export { GlobalRole } from './roles.constants.hq';

export const ROLE_SCOPE = {
  organization: 'organization',
  property: 'property',
  tenant: 'tenant',
  global: 'global',
};

export enum VoltaxRole {
  AnalyticsPublisherExternalDemand = 'external_demand_dash',
  AnalyticsDisplay = 'display_dash',
  AnalyticsRevenueOverview = 'overview_dash',
  AnalyticsPlayerRevenue = 'player_revenue_dash',
  AnalyticsSSPRevenue = 'ssp_dash',
  AnalyticsDashboardsViewer = 'analytics_dashboards_viewer',
  AnalyticsDisplayMonetizationReportsBuilder = 'display_monetization_reports_builder',
  AnalyticsHomepageDashboardViewer = 'analytics_homepage',
  AnalyticsTotalRevenueOverview = 'analytics_total_revenue_overview',
  AnalyticsMMSSPRevenue = 'analytics_mm_ssp_revenue',
  AnalyticsVoltaxPlayerOverview = 'analytics_voltax_player_overview',
  AnalyticsUnifiedPlayerOverview = 'analytics_unified_player_overview',
  CmsWriter = 'cms_writer',
  CmsEditor = 'cms_editor',
  CmsManager = 'cms_manager',
  ContentEnrichmentAdmin = 'content_enrichment_admin',
  CraftAdmin = 'craft_admin',
  CraftManager = 'craft_manager',
  FSSiteStatsViewer = 'fs_site_stats_viewer',
  FSMyStatsViewer = 'fs_my_stats_viewer',
  MediaAdmin = 'media_admin',
  MediaContributor = 'media_contributor',
  OrganizationAdmin = 'organization_admin',
  SetupManager = 'setup_manager',
  VmsEditor = 'vms_editor',
  VmsManager = 'vms_manager',
  VmsExplorer = 'vms_explorer',
  VmsBrowser = 'vms_browser',
  InjectionsManager = 'injections_manager',
  UploadImages = 'upload_images',
  PlayerHBTab = 'player_HBTab',
  PlayerMonetizationTab = 'player_MonetizationTab',
  MiniPlayerTab = 'player_MiniPlayerTab',
  PlayerAdServerTab = 'player_adserver',
  ManualMigrationEditor = 'manual_migration_editor',
  QueryBuilderViewer = 'reports_builder_viewer_organization',
  FansidedImageExchangeUser = 'fs_image_exchange_user',
  UnifiedPlayerDashboardViewer = 'unified_player_dashboards_viewer',
  SingleMigrateRole = 'single-migrate-role',
  ResetPasswordAdmin = 'reset_password_admin',
  CredentialsAdmin = 'user_credentials_admin'
}

export const stnLogoSlugs = [
  'voqally',
  'voice-media-group',
  'essentially-sports',
  'reach-plc',
  'h-and-l-media-inc',
  'redbrick',
  'ocala-news',
  'adams-publishing-group',
  'icisic-media',
  'beasley-media-group',
  'schneps-media',
  'lake-county-mchenry-county-scanner',
  'firstsportz',
  'roi-media',
  'iheart',
  'victor-valley-news',
  'better-collective',
  'drugscom',
];
