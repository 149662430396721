export enum Product {
  CMS = 'cms',
  VMS = 'vms',
  Administration = 'administration',
  Organization = 'organization',
  Campaignmanager = 'campaignmanager',
  Reports = 'reports',
  Syndication = 'syndication',
  Marketplace = 'marketplace',
  Injections = 'injections',
  Onboarding = 'onboarding',
  Experiments = 'experiments',
  AdsTxt = 'adsTxt',
  Adserver = 'adserver',
  Contributorsanalytics = 'contributorsanalytics',
  Analytics = 'analytics',
  Monetization = 'monetization',
  UDS = 'mntz-display',
  Alerts = 'alerts',
}
