import { COLORS } from '../../../../../../core/styles/colors';

export const EDIT_URL_MIN_LENGTH = 2;

export const METADATA_TEXT = {
  SECTION_TITLE: 'Article Customization',
  SECTION_TOOLTIP: 'Customize your article’s metadata. Improve SEO, configure author settings or add branding.',
};

export const DFP_CATEGORY_DROPDOWN_TITLE = 'DFP Category';
export const DFP_CATEGORY_DROPDOWN_PLACEHOLDER = 'Choose DFP Category';

export const MAIN_CATEGORY_DROPDOWN_TITLE = 'Main Category';
export const MAIN_CATEGORY_DROPDOWN_PLACEHOLDER = 'Select category';
export const MAIN_CATEGORY_TOOLTIP_TEXT = 'The Main Category will be used for labeling articles within the site';
export const TAG_ALREADY_EXISTS = 'This tag already exists';

export const AUTHORS_DROPDOWN_TITLE = 'Available Authors';
export const AUTHORS_DROPDOWN_PLACEHOLDER = 'Type author\'s name';

export const TOPIC = 'Topic';
export const VERTICAL = 'Vertical';
export const TOPIC_VERTICAL_PLACEHOLDER = {
  Topic: 'Select a topic',
  Vertical: 'Select a vertical',
};
const ORDERED_TOPICS_LIST = ['49ers', '76ers', 'Aggies', 'All Bay Area Sports', 'All Boston Sports', 'All Celebrities', 'All Chicago Sports', 'All Cleveland Sports',
  'All Detroit Sports', 'All DFW Sports', 'All English Premier League', 'All Golf', 'All Houston Sports', 'All KC Sports', 'All LA Sports', 'All Liga MX', 'All MLB',
  'All MMA', 'All NBA', 'All NFL', 'All NY Sports', 'All Philly Sports', 'All Racing', 'All SEC', 'All Sports Betting', 'All Television', 'All Tennessee Sports',
  'All Toronto Sports', 'All Wisconsin Sports', 'Amazon', 'Angels', 'App Trigger', 'Arsenal FC', 'Aston Villa', 'Astros', 'Athletics', 'Avalanche', 'Badgers',
  'Bayern Munich', 'Bearcats', 'Bears', 'Beavers', 'Bengals', 'Bills', 'Blackhawks', 'Blue Devils', 'Blue Jackets', 'Blue Jays', 'Blues', 'Borussia Dortmund',
  'Braves', 'Brewers', 'Broncos', 'Browns', 'Bruins', 'Buccaneers', 'Buckeyes', 'Bucks', 'Buffaloes', 'Bulldogs', 'Bulls', 'Busting Brackets', 'Call to the Pen',
  'Canadiens', 'Canucks', 'Capitals', 'Cardinals', 'Cars', 'Cavaliers', 'Celtic FC', 'Celtics', 'Chargers', 'Chelsea FC', 'Chica', 'Chiefs', 'Clippers', 'Colts',
  'Columbus Crew FC', 'Commanders', 'Cooking Light', 'Cornhuskers', 'Costal Living', 'Cougars', 'Cowboys', 'Coyotes', 'Crimson Tide', 'Cubs', 'Culturess', 'Departures',
  'Devils', 'Diamondbacks', 'Dodgers', 'Dog Pics', 'Dolphins', 'Dota 2', 'Ducks', 'Eagles', 'Entertainment Weekly', 'eSports', 'Essence', 'Everton', 'Falcons',
  'FanSided', 'FanSided.com', 'Fantasy Football', 'FC Barcelona', 'FC Schalke 04', 'Fighting Illini', 'Fighting Irish', 'Flames', 'Flyers', 'Food', 'Food and Wine', 'Food Reviews, Recipes & More',
  'Fortnite', 'Fortune', 'Game of Thrones', 'Gamecocks', 'Gators', 'Giants', 'Golden Knights', 'Golf', 'Grizzlies', 'Guardians', 'Hawkeyes', 'Hawks', 'Health',
  'Heat', 'Hip Hop', 'Hokies', 'Hoops Habit', 'Hoosiers', 'Hornets', 'Horror Movies', 'Houston Dynamo', 'Hurricanes', 'Huskies', 'InStyle', 'Inter Miami CF',
  'Islanders', 'Jaguars', 'Jayhawks', 'Jazz', 'Jets', 'Juventus', 'Kings', 'Knicks', 'Kraken', 'La Cultura Pop', 'LA Galaxy', 'Lakers', 'Late Night Television',
  'League of Legends', 'Leicester City', 'Life', 'Lightning', 'Lions', 'Liverpool', 'Longhorns', 'Magic', 'Manchester City', 'Manchester United', 'Maple Leafs',
  'Mariners', 'Marlins', 'Mavericks', 'Mets', 'MLS Multiplex', 'Money', 'Motto', 'Mountaineers', 'My Recipies', 'Nationals', 'Netflix', 'Nets', 'New York City FC',
  'Newcastle United', 'NFL Draft', 'NFL Spin Zone', 'Nittany Lions', 'Nuggets', 'Oilers', 'One Chicago', 'Orange', 'Orioles', 'Orlando City SC', 'Outlander', 'Pacers',
  'Packers', 'Padres', 'Panthers', 'Paris Saint-Germain FC', 'Patriots', 'Pelicans', 'Penguins', 'People', 'People En Español', 'PetHero', 'Phillies', 'Pirates',
  'Pistons', 'Playing for 90', 'Portland Timbers', 'Predators', 'Puck Prose', 'Raiders', 'Rams', 'Rangers', 'Rangers FC', 'Raptors', 'Ravens', 'Rays', 'Razorbacks',
  'Real Madrid', 'Real Salt Lake', 'Rebels', 'Red Raiders', 'Red Sox', 'Red Storm', 'Red Wings', 'Reds', 'Rockets', 'Rockies', 'Royals', 'Sabres', 'Saints',
  'Saturday Blitz', 'Seahawks', 'Seattle Sounders FC', 'Seminoles', 'Senators', 'Sharks', 'Show Snob', 'SI', 'SI Kids', 'Sir Charles in Charge', 'Sneakers', 'Sooners',
  'Southampton', 'Southern Living', 'Spartans', 'Sporting Kansas City', 'Spurs', 'Star Trek', 'Star Wars', 'Stars', 'Steelers', 'Stoke City', 'Sun Devils', 'Suns',
  'Sunset', 'Superheroes and Comics', 'Survivor', 'Tar Heels', 'Television', 'Tennis', 'Terrapins', 'Texans', 'The Bachelor', 'The Kardashians', 'The Walking Dead',
  'Thunder', 'TICP', 'Tigers', 'Timberwolves', 'Time', 'Titans', 'Toronto FC', 'Tottenham Hotspur', 'Trail Blazers', 'Travel', 'Travel and Leisure', 'Trojans',
  'TV Crime Dramas', 'Twins', 'Usf Bulls', 'Vikings', 'Valkyries', 'Volunteers', 'Warriors', 'West Ham United', 'White Sox', 'Wild', 'Wildcats', 'Wizards', 'Wolverines',
  'Women\'s Basketball', 'WWE', 'XO Jane', 'Yankees', 'Yellow Jackets', 'Boston Bruins', 'UCLA Bruins', 'BYU Cougars', 'Washington State Cougars', 'Oklahoma State Cowboys',
  'Dallas Cowboys', 'Oregon Ducks', 'Anaheim Ducks', 'San Francisco Giants', 'New York Giants', 'Miami Hurricanes', 'Carolina Hurricanes', 'Winnipeg Jets', 'New York Jets',
  'Sacramento Kings', 'Los Angeles Kings', 'Florida Panthers', 'Carolina Panthers', 'New York Rangers', 'Texas Rangers', 'Kentucky Wildcats', 'Arizona Wildcats', 'Missouri Tigers',
  'LSU Tigers', 'Aubum Tigers', 'Detroit Tigers', 'Clemson Tigers', 'Kansas State Wildcats', 'All Books', 'Social Media', 'TLC Network', 'Candy', 'Knights', 'Fever', 'Sky', 'Manchester United',
  'College Football', 'College Basketball', 'Lego', 'All Movies', 'Spider-Man', 'Batman', 'Harry Potter', 'Utah Hockey Club', 'True Crime', 'Doctor Who', 'Hulu', 'Political Dramas',
  'Big Brother', 'The Voice', 'Supernatural', 'Dating Reality TV', 'Soap Operas', 'The Simpsons', 'Pokémon', 'Romance Dramas', 'James Bond', 'X-Men', 'Utes'].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

export const TOPIC_VERTICAL_LIST = {
  Topic: ORDERED_TOPICS_LIST,
  Vertical: ['ENTERTAINMENT', 'ESPORTS', 'EXTRA', 'FANTASY SPORTS', 'FS', 'LIFESTYLE', 'LOCAL', 'MLB', 'NBA', 'NCAA', 'NCAAB', 'NCAAF', 'NFL', 'NHL', 'OLYMPICS', 'POLITICS', 'SOCCER', 'TV'],
};

export const EDIT_URL_LABEL = 'Edit URL';
export const EDIT_URL_ERROR = 'Edit url is not valid';
export const DEFAULT_ADS_CATEGORY = 'Default';

export const NO_INDEX_TEXT = 'Do not index article on Google';
export const PREMIUM_ARTICLE_TEXT = 'Premium Article';
export const EXCLUDE_FROM_RSS_TEXT = 'Exclude from RSS';
export const EXCLUDE_FROM_RECOMMENDATIONS_TEXT = 'Exclude from Recommendations';

export const SPONSORED_ARTICLE_FIELDS = {
  URL: {
    LABEL_PROPS_TEXT: 'URL',
    FIELD_NAME: 'url',
    PLACEHOLDER: 'Enter sponsor’s URL',
  },
  TEXT: {
    LABEL_PROPS_TEXT: 'Sponsored text',
    FIELD_NAME: 'text',
    PLACEHOLDER: 'Example: Presented by',
    MAX_LENGTH: 40,
  },
  BRAND_NAME: {
    LABEL_PROPS_TEXT: 'Brand name',
    FIELD_NAME: 'brandName',
    PLACEHOLDER: 'Enter brand\'s name',
    MAX_LENGTH: 40,
  },
  IMAGE: {
    LABEL_PROPS_TEXT: 'Sponsored logo',
    FIELD_NAME: 'image',
    SPONSORED_LOGO_BUTTON_TEXT: 'Upload a logo',
    TOOLTIP_TEXT: 'Logo file format must be SVG',
  },
};

export const PUBLISH_DATE_TITLE = 'Change publish date';
export const PUBLISH_DATE_DEFAULT = new Date(2014, 0, 1);

export const MANUAL_RECOMMENDATIONS_TITLE = 'Manual Recommendations';
export const MANUAL_RECOMMENDATIONS_TOOLTIP = 'Add recommended articles that will appear at the end of your post page';
export const MANUAL_RECOMMENDATIONS_PLACEHOLDER = 'Type here to search for articles…';
export const MANUAL_RECOMMENDATION_DESCRIPTION = 'This will only apply to specific post page templates';
export const REMOVE_RECOMMENDATIONS_ITEM_TOOLTIP = 'Remove article';
export const MANUAL_RECOMMENDATIONS_LIMIT = 4;

export const BETTING_CONTENT_TITLE = 'Betting Content';
export const BETTING_CONTENT_TOOLTIP = 'Add custom data to your betting content';
export const BETTING_CONTENT_FIELDS = {
  ACTIVITY: {
    ITEMS: ['Sportsbook', 'Casino', 'DFS', 'Other'],
    LABEL: 'Activity',
    PLACEHOLDER: 'Choose activity',
    NAME: 'activity',
  },
  CHANNEL: {
    ITEMS: ['Facebook', 'Google', 'Twitter', 'Influencer', 'Snapchat', 'TikTok', 'Reddit', 'Bing', 'Outbrain', 'Taboola', 'Podcast', 'O&O', 'Other'],
    LABEL: 'Channel',
    PLACEHOLDER: 'Choose channel',
    NAME: 'channel',
  },
  CAMPAIGN_ID: {
    LABEL: 'Campaign ID',
    PLACEHOLDER: 'Enter Campaign ID',
    NAME: 'campaignID',
  },
};
export const BETTING_CONTENT_ERROR = 'You must fill in at least one betting field';

export const SPONSORED_ARTICLE_TITLE = 'Sponsored Article';
export const CANONICAL_URL_TITLE = 'Canonical URL';
export const CANONICAL_URL_TOOLTIP = [
  'This URL will represent the source of the article. If left empty, the article URL will be used as the canonical',
  'Articles with an external canonical URL are recommended to be excluded from RSS',
];

export const CANONICAL_URL_INPUT_FIELD = {
  PLACEHOLDER: 'Enter canonical URL',
  EMPTY_URL_ERROR: 'A valid Canonical URL must be added or turned off',
  ULR_ERROR: 'URL must start with "http://" or "https://"',
};

export const CANONICAL_URL_ERROR = 'A valid Canonical URL must be added or turned off';

export const SPONSORED_SECTION_INSTRUCTIONS = {
  SECTION_TOOLTIP: 'Add custom branding to your article',
  SECTION_INSTRUCTIONS: 'By inserting the logo and brand name you acknowledge that you have the right to use it',
};

export const SPONSORED_ERROR_MESSAGES = {
  TEXT: 'You can’t leave this field empty',
  FILE_EXTENSION: 'Logo file format must be SVG',
  IMAGE_IS_NOT_VALID: 'Image is not valid',
};

export const SPONSORED_LOGO_FILE_NAME_PLACEHOLDER = 'Sponsored Logo';

export const ALLOWED_FILE_EXTENSION = 'svg';

export const COMMERCIAL_TAGS = {
  DROPDOWN_TITLE: 'Commercial Tags',
  DROPDOWN_PLACEHOLDER: 'Add a configuration',
  DROPDOWN_OPTIONS: [
    'no-video-player',
    'no-campaign-manager',
    'no-ads',
    'no-external-recommendation',
    'no-finite-scroll',
    'no-pn',
    'flat-rate',
    'no-related-posts',
    'exclude_fs_syndication',
    'sponsored',
    'disclaimer',
    'review-disclaimer',
    'no-related-topics',
    'no-live-comments',
    'no-hero-image',
  ],
  DROPDOWN_NO_OPTIONS: 'Add custom tag',
};

export const SPONSORED_CONTENT_WRAPPER_ID = 'sponsor-content-wrapper';
export const MANUAL_RECOMMENDATION_CONTENT_WRAPPER_ID = 'manual-recommendation-content-wrapper';
export const BETTING_CONTENT_WRAPPER_ID = 'betting-content-wrapper';

export const SVG_PREVIEW_BACKGROUNDS = [
  COLORS.WHITE,
  COLORS.BLACK30,
  COLORS.BLACK50,
  COLORS.BLACK70,
  COLORS.BLACK100,
];

export const DEFAULT_PREVIEW_OPTIONS = {
  background: SVG_PREVIEW_BACKGROUNDS[0],
};

export const SVG_PREVIEW_BACKGROUND_TEXT = 'Preview background';

export const FULL_BLEED = 'full-bleed';
export const TEAMS = 'Teams';
