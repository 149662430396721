import { isEmpty, isPositiveInteger } from '@ftbpro/mm-admin-core-utils';
import { createRegExValidator, createValidator } from 'core/utils/validationsBeta/validations';
import { AD_SERVER_VALIDATION_ERRORS } from './adServer.strings';

export const mandatoryFieldValidator = (value: string) => !isEmpty(value.trim());

export const isPositiveNonZeroInteger = (text: string) => {
  const n = Math.floor(Number(text));
  return n !== Infinity && String(n) === text && n > 0;
};

export const isPositiveNonZeroIntegerWithComa = (text: string) => {
  const newValue = text.replace(/,/g, '');
  const n = Math.floor(Number(newValue));
  return n !== Infinity && n > 0;
};

export const isNumberInRange = (min: number, max: number) => (number: string) => {
  const n = isEmpty(number) ? NaN : Math.floor(Number(number));
  return n !== Infinity && String(n) === number && n > 0 && n >= min && n <= max;
};

export const isValidLength = (minLength: number, maxLength: number) => (text: string) => {
  // Split the text by comma and remove empty strings, then filter empty strings
  const strings = text.split(/,\s*/).filter(s => s);
  return strings.every(s => s.length >= minLength && s.length <= maxLength);
};

export const consistsDigitsOnly = (text: string) => {
  const strings = text.split(/,\s*/).filter(s => s);
  return !isEmpty(strings) && strings.every(s => /^\d+$/.test(s));
};

export const isNonNegativeFloatInRightFormat = (text: string) => {
  const parsedText = Number(text);
  const hasLeadingZeros = text.startsWith('0') && !text.startsWith('0.') && parsedText !== 0;
  const isInRightFormat = !text.startsWith('.') && !text.endsWith('.') && !text.startsWith('-');
  const hasMoreThanTwoDigitsAfterDecimalPoint = text.includes('.') && text.substring(text.indexOf('.') + 1).length > 2;
  return (!Number.isNaN(parsedText) && parsedText >= 0 && !hasLeadingZeros && isInRightFormat && !hasMoreThanTwoDigitsAfterDecimalPoint);
};

export const isPositiveFloatInRightFormat = (text: string) => {
  return isNonNegativeFloatInRightFormat(text) && Number(text) > 0;
};

const REGEX_ONLY_ALPHANUMERIC = new RegExp('^[a-zA-Z0-9]*$');

const REGEX_ONLY_ALPHANUMERIC_PERIOD_AND_UNDERSCORE = new RegExp('^[a-zA-Z0-9._]*$');

const REGEX_ONLY_ALPHANUMERIC_PERIOD_AND_SPACE = new RegExp('^[a-zA-Z0-9.\\s]*$');

const REGEX_ONLY_ALPHANUMERIC_DASH_SPACE_AND_UNDERSCORE = new RegExp('^[a-zA-Z0-9-_\\s]*$');

const REGEX_ONLY_ALPHANUMERIC_AND_PACES = new RegExp('^[a-zA-Z0-9- ]*$');

const REGEX_NON_SPACES = new RegExp('^\\S*$');

const REGEX_ONLY_SOME_SPECIAL_CHARS = new RegExp('^[a-zA-Z0-9_@./#&+\\-|()\'\\$\\s]*$');

const REGEX_VALID_SECURED_URL = new RegExp('^https://[^\\s]*$');

export const AD_SERVER_COMMON_VALIDATORS = {
  mandatory: createValidator(mandatoryFieldValidator, AD_SERVER_VALIDATION_ERRORS.MANDATORY_FIELD),
  validPositiveInteger: createValidator(isPositiveNonZeroInteger, AD_SERVER_VALIDATION_ERRORS.INVALID_POSITIVE_INTEGER),
  validPositiveIntegerWithComa: createValidator(isPositiveNonZeroIntegerWithComa, AD_SERVER_VALIDATION_ERRORS.INVALID_POSITIVE_INTEGER),
  validPositiveAndZeroInteger: createValidator(isPositiveInteger, AD_SERVER_VALIDATION_ERRORS.INVALID_POSITIVE_INTEGER_AND_ZERO),
  onlyAlphanumeric: createRegExValidator(REGEX_ONLY_ALPHANUMERIC, AD_SERVER_VALIDATION_ERRORS.SPECIAL_CHARS_NOT_PERMITTED),
  onlyAlphanumericAndSpaces: createRegExValidator(REGEX_ONLY_ALPHANUMERIC_AND_PACES, AD_SERVER_VALIDATION_ERRORS.SPECIAL_CHARS_NOT_PERMITTED_EXCEPT_SPACES),
  onlyAlphanumericPeriodAndUnderscore: createRegExValidator(REGEX_ONLY_ALPHANUMERIC_PERIOD_AND_UNDERSCORE, AD_SERVER_VALIDATION_ERRORS.SPECIAL_CHARS_EXCEPT_UNDERSCORE_AND_PERIOD_NOT_PREMITTED),
  onlyAlphanumericPeriodAndSpace: createRegExValidator(REGEX_ONLY_ALPHANUMERIC_PERIOD_AND_SPACE, AD_SERVER_VALIDATION_ERRORS.SPECIAL_CHARS_NOT_PREMITTED_EXCEPT_PERIODS),
  onlyAlphanumericDashSpaceAndUnderscore: createRegExValidator(REGEX_ONLY_ALPHANUMERIC_DASH_SPACE_AND_UNDERSCORE, AD_SERVER_VALIDATION_ERRORS.SPECIAL_CHARS_EXCEPT_UNDERSCORE_AND_DASH_NOT_PERMITTED),
  noSpaces: createRegExValidator(REGEX_NON_SPACES, AD_SERVER_VALIDATION_ERRORS.SPACES_NOT_PREMITTED),
  onlySomeSpecialChars: createRegExValidator(REGEX_ONLY_SOME_SPECIAL_CHARS, AD_SERVER_VALIDATION_ERRORS.SOME_SPECIAL_CHARS_NOT_PREMITTED),
  positiveFloatInRightFormat: createValidator(isPositiveFloatInRightFormat, AD_SERVER_VALIDATION_ERRORS.INVALID_POSITIVE_FLOAT),
  validSecuredURL: createRegExValidator(REGEX_VALID_SECURED_URL, AD_SERVER_VALIDATION_ERRORS.INVALID_SECURED_URL_ERROR),
  validNumberRange: (min: number, max: number) => createValidator(isNumberInRange(min, max), AD_SERVER_VALIDATION_ERRORS.INVALID_NUMBER_RANGE(min, max)),
  validStringLength: (min: number, max: number) => createValidator(isValidLength(min, max), AD_SERVER_VALIDATION_ERRORS.INVALID_LENGTH),
  onlyDigits: createValidator(consistsDigitsOnly, AD_SERVER_VALIDATION_ERRORS.NOT_ONLY_DIGITS),
};
